body {
  margin: 0;
  padding: 0;
}
.bg-abbott-black{
  background-color: #333;
}
.bg-abbott-gray{
  background-color: #d9d9d7;
}
.bg-abt-blue{
  background-color: #52c0e6;
}
.bg-abt-blue-dark{
  background-color: #001d34;
}
.bg-white{
  background-color: #FFF;
}
.bg-green-lite{
  background-color: #84c778;
}

.abbott-logo{
  background-image: url('./assets/img/logo.png');
}

/* Drop Down */
.dropdown-parent{
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-child{
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.dropdown-child ul {
  list-style-type: none;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #001d34;
  background-color: #FFF;
  font-size: 22px;
  width: 250px;
}

.dropdown-parent:hover .dropdown-child{
  visibility: visible;
}

.dropdown-off{
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
} 

.dd-parent-button {
  border: none;
  font-size: 24px;
  resize: none;
  margin-top: 5px;
}

.dd-child-button {
  padding: 10px;
}

.success{
  position: absolute;
  top:0;
  left:0;
  color:white;
  font-size: 42px;
  font-family: Tahoma, sans-serif;
  text-align: center;
}
.success img{
  width: 400px;
  height: 400px;
  margin: auto;
  margin-top: 100px;
}
/* Styling */
.overflow-hidden{
  overflow: hidden;
}
.bg-image{
  background-repeat: no-repeat;
  background-size: contain;
}
.w-full{
  width: 100vw;
}
.h-full{
  height: 100vh;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.flex{
  display: flex;
}
.flex-auto{
  flex: 1 1 auto;
}
.shrink{
  flex-shrink: 1;
}
.touch-none{
  touch-action: none;
}
.grow{
  flex-grow: 1;
}

.justify-center{
  justify-content: center;
}
.justify-end{
  justify-content: flex-end;
}

.items-center{
  align-items: center;
}

.float-right{
  float: right;
}

.mt-20{
  margin-top: 20px;
}
.mr-7{
  margin-right: 28px;
}
.select-none{
  user-select: none;
}
.cursor-pointer{
  cursor: pointer;
}

.square-button {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  border: 0px;
  border-radius: 10px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin: 2px;
  text-align: center;
}

.tile-text {
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #001d34;
  background-color: #FFF;
  border-radius: 4px;
  font-size: 24px;
  resize: none;
}
/* Spinner */
.spinner {
  border-radius: 50%;
  border: 40px solid #00000010;
  border-top: 40px solid #84c778;
  border-bottom: 40px solid #84c77850;
  width: 200px;
  height: 200px;
  margin: auto;
  margin-top: 200px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* modal; */

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-text-editor {
  background-color: #f2f3f5;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close {
 display: flex;
 
  justify-content: flex-end;
  align-items: center;
 
}

.close-btn {
  width: fit-content;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 5px 7px;
  background-color: #fff;

  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 640px) {
  .container {max-width: 640px;}
}
@media (max-width: 768px) {
  .container {max-width: 768px;}
}
@media (max-width: 1024px) {
  .container {max-width: 1024px;}
}
@media (max-width: 1280px) {
  .container {max-width: 1280px;}
}
@media (max-width: 1536px) {
  .container {max-width: 1536px;}
}