.abbott-logo-icon{
    
    background-position: center;

}
.cancel-btn {
    background-image: url('../../assets/img/draw-cancel.png');
}

.clear-btn {
    background-image: url('../../assets/img/draw-clear.png');
}

.undo-btn {
    background-image: url("../../assets/img/draw-undo.png")
}

.save-btn {
    background-image: url("../../assets/img/draw-save.png");
}


.text-btn {
    background-image: url("../../assets/img/icon-text.svg");
    background-color: #912DE3;
}

.text-btn-disabled {
    background-color: #d6b5ee;
}


.upload-success {
    position: absolute;
    background-image: url('../../assets/img/draw-success.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400px 400px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 100px;
    z-index: 21;
    background-color: #76ca6e;
}