.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DrawingApp{
  width: 100vw;
  height: 100vh;
}